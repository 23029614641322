import { RouterModule } from '@angular/router';
//Component
import { AdminLoginComponent } from './Admin/admin-login/admin-login.component';
import { DashboardComponent } from './Admin/dashboard/dashboard.component';
import { ProfileComponent } from './Admin/profile/profile.component';
import { AddEditProviderComponent } from './Admin/providers/add-edit-provider.component';
import { ProvidersComponent } from './Admin/providers/providers.component';
import { AddEditUserComponent } from './Admin/Users/add-edit-user.component';
import { AdminLayoutComponent } from './Layout/admin-layout/admin-layout.component';
import { AuthGuard } from './Shared/authGuard';
import { ForgotpasswordComponent } from './Admin/admin-login/forgotpassword.component';
import { UsersComponent } from './Admin/Users/users.component';
import { EmailTemplateComponent } from './Admin/email-template/email-template.component';
import { EditemailtemplateComponent } from './Admin/email-template/editemailtemplate.component';
import { ReportdetailComponent } from './Admin/Report/reportdetail/reportdetail.component';
import { NewregistrationComponent } from './admin/newregistration/newregistration.component';
import { ApplicationreviewComponent } from './admin/applicationreview/applicationreview.component';
import { CompanyprofileComponent } from './admin/companyprofile/companyprofile.component';
import { LocationComponent } from './admin/location/location.component';
import { AddEditLocationComponent } from './admin/location/add-edit-location.component';
import { ZipcodeComponent } from './admin/location/zipcode.component';
import { UserrightsComponent } from './Admin/userrights/userrights.component';
import { EligibilityCheckStatusComponent } from './Admin/eligibility-check-status/eligibility-check-status.component';
import { AnalyticalReportComponent } from './admin/Report/analytical-report/analytical-report.component';
import { UnderMaintenanceComponent } from './Admin/under-maintenance/under-maintenance.component';
import { ManageMaintenanceComponent } from './Admin/under-maintenance/manage-maintenance.component';
import { RateSetupComponent } from './Admin/rate-setup/rate-setup.component';
import { RateSetupHistoryComponent } from './Admin/rate-setup-history/rate-setup-history.component';
import { BillingExportComponent } from './Admin/billing-export/billing-export.component';
import { InvoiceListingComponent } from './Admin/Invoice/invoice-listing.component';
import { ManageInvoiceComponent } from './Admin/Invoice/manage-invoice.component';
import { SubscribersComponent } from './Admin/subscribers/subscribers.component';
import { ApplicantsComponent } from './Admin/applicants/applicants.component';
import { ManageSubscriberComponent } from './Admin/subscribers/manage-subscriber.component';
import { ManageApplicationComponent } from './Admin/applicants/manage-application.component';
import { AddNewUserComponent } from './Admin/applicants/add-new-user.component';
import { OnlineRegistrationListingComponent } from './Admin/online-registration-listing/online-registration-listing.component';
import { ViewConsentComponent } from './Admin/view-consent/view-consent.component';
// import { ProviderZoneComponent } from './Admin/provider-zone/provider-zone.component';
// import { AddEditProviderZoneComponent } from './Admin/provider-zone/add-edit-provider-zone.component';
import { ConsentHistoryComponent } from './Admin/applicants/consent-history.component';
import { ApplicantImportComponent } from './Admin/applicant-import/applicant-import.component';

import { ManageTransferApplicationComponent } from './Admin/applicants/manage-transfer-application.component';
import { SnapshotandSummaryReportComponent } from './Admin/applicants/snapshot-summary-report.component';
import { SnapshotandSummaryDetailsReportComponent } from './Admin/applicants/snapshot-summary-details-report.component';
import { NladVSInrollComponent } from './Admin/applicants/nlad-vs-inroll.component';
import { ViewApplicationComponent } from './Admin/applicants/view-application.component';

export const Approutes = RouterModule.forRoot([
  // { path: '', redirectTo: 'login', pathMatch: 'full' },
  { path: '', redirectTo: 'register', pathMatch: 'full' },
  { path: 'admin', component: AdminLoginComponent },
  { path: 'admin/login', component: AdminLoginComponent },
  { path: 'admin/login/:id', component: AdminLoginComponent },
  { path: 'admin/forgotpassword', component: ForgotpasswordComponent },
  {
    path: 'admin',
    component: AdminLayoutComponent,
    canActivateChild: [AuthGuard],
    children: [
      { path: 'dashboard', component: DashboardComponent },
      { path: 'users', component: UsersComponent },
      { path: 'emailTemplates', component: EmailTemplateComponent },
      { path: 'emailTemplate/edit/:id', component: EditemailtemplateComponent },
      { path: 'providers', component: ProvidersComponent },
      { path: 'providers/edit/:id', component: AddEditProviderComponent },
      { path: 'providers/users/:id', component: UsersComponent },
      { path: 'providers/users/:id/:usertype', component: UsersComponent },
      { path: 'providers/users/add/:pid', component: AddEditUserComponent },
      { path: 'providers/users/add/:pid/:usertype', component: AddEditUserComponent },
      { path: 'providers/users/edit/:id/:pid/:usertype', component: AddEditUserComponent },
      { path: 'users/:id', component: UsersComponent },
      { path: 'users/:id/:usertype', component: UsersComponent },
      { path: 'users/add/:pid', component: AddEditUserComponent },
      { path: 'users/add/:pid/:usertype', component: AddEditUserComponent },
      { path: 'users/edit/:id/:pid/:usertype', component: AddEditUserComponent },
      { path: 'user/profile', component: ProfileComponent },
      { path: 'analytical-report', component: AnalyticalReportComponent },
      { path: 'userrole', component: UserrightsComponent },
      { path: 'newregistration', component: NewregistrationComponent },
      { path: 'recertify', component: NewregistrationComponent },
      { path: 'newregistration/review/:id', component: ApplicationreviewComponent },
      { path: 'recertify/review/:id', component: ApplicationreviewComponent },
      { path: 'companyprofile/preview/:id', component: CompanyprofileComponent },
      { path: 'status/check', component: EligibilityCheckStatusComponent },
      { path: 'maintenances', component: UnderMaintenanceComponent },
      { path: 'maintenance/add', component: ManageMaintenanceComponent },
      { path: 'maintenance/edit/:id', component: ManageMaintenanceComponent },
      { path: 'locations', component: LocationComponent },
      { path: 'location/add/:id', component: AddEditLocationComponent },
      { path: 'location/edit/:id/:pid', component: AddEditLocationComponent },
      { path: 'location/zipcodes/:id', component: ZipcodeComponent },
      { path: 'rate/history/:pid', component: RateSetupHistoryComponent },
      { path: 'rate/setup/:pid', component: RateSetupComponent },
      { path: 'billing/export', component: BillingExportComponent },
      { path: 'invoice', component: InvoiceListingComponent },
      { path: 'invoice/add', component: ManageInvoiceComponent },
      { path: 'invoice/edit/:id', component: ManageInvoiceComponent },
      { path: 'subscribers', component: SubscribersComponent },
      { path: 'subscriber/edit/:id', component: ManageSubscriberComponent },
      { path: 'applicants', component: ApplicantsComponent },
      { path: 'applicants/edit/:id/:id2', component: ManageApplicationComponent },
      { path: 'applicants/addnew', component: AddNewUserComponent },
      { path: 'applicants/addnew/:id', component: AddNewUserComponent },
      { path: 'online/register/user', component: OnlineRegistrationListingComponent },
      { path: 'viewconsent', component: ViewConsentComponent },
      { path: 'consent-history/:id', component: ConsentHistoryComponent },
      { path: 'applicants/transfer/:id/:id2', component: ManageTransferApplicationComponent },
      { path: 'applicants/import', component: ApplicantImportComponent },
      { path: 'snapshot-summary-report', component: SnapshotandSummaryReportComponent },
      { path: 'snapshot-summary-details-report', component: SnapshotandSummaryDetailsReportComponent },
      { path: 'nlad-vs-inroll-report', component: NladVSInrollComponent },
      { path: 'applicants/view', component: ViewApplicationComponent },
      // { path: 'providerzsone', component: ProviderZoneComponent },
      // {
      //   path: 'providerzone/add/:id',
      //   component: AddEditProviderZoneComponent
      // },
      // {
      //   path: 'providerzone/edit/:id/:pid',
      //   component: AddEditProviderZoneComponent
      // },
      // { path: 'subscribeDetails/:id', component: RegistrationreportComponent },
      { path: '**', redirectTo: 'dashboard' }
    ]
  },

  // {
  //   path: 'whipcityfiber',
  //   loadChildren: './subscriber/subscriber.module#SubscriberModule'
  // },

  {
    path: 'ebb',
    loadChildren: './ebb-subscriber-side/ebb-subscriber-side.module#EBBSubscriberSideModule'
  }, 
  { path: '**', redirectTo: 'ebb' },
  // {
  //   path: 'whipcityfiber',
  //   loadChildren: './subscriber-side/subscriber-side.module#SubscriberSideModule'
  // },  
  
  // { path: '**', redirectTo: 'whipcityfiber' },

  
]);

